import React from 'react';
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {BlogDetailsContent, BlogPostDetailsWrapper, TagName, TagPageHeading} from "./templates.style";
import Sidebar from "../containers/sidebar";
import {PostGrid, PostRow} from "../components/post/post.style";
import PostCardModern from "../components/post-card-modern/post-card-modern";
import SEO from "../components/seo";
import Pagination from "../components/pagination/pagination";


const Tag = ({ pageContext, data}: any) => {

    const { slug,page,perPage,name } = pageContext;
    const { edges, totalCount } = data.allWordpressPost;

    const description = data.wordpressTag?.description;

    const colors = ['#E33974', '#006EE5', '#4F4DBF', '#784D74'];
    function getRandomColor(values: string[]) {
        const random = Math.floor(Math.random() * values.length);
        return values[random];
    }


    const isFirst = page === 0;
    const numPages = Math.ceil(totalCount/perPage);
    const isLast = page === numPages-1;
    const prevPage =
        page === 1 ? `/tags/${slug}` : `/tags/${slug}/page/${(page).toString()}`;



    const nextPage = (page === 0) ? `/tags/${slug}/page/2` : `/tags/${slug}/page/${(page + 2).toString()}`;
    const PrevLink = !isFirst && prevPage;
    const NextLink = !isLast && nextPage;



    let metaTitle = (page===0)?name:`${name} - ${page+2}`;
    let metaDescription = (description!==null && description!=="")?description:null;

    return (
        <Layout>


            <SEO  title={metaTitle} description={metaDescription}/>

            <BlogPostDetailsWrapper>
                <BlogDetailsContent>

                    <TagPageHeading>
                        <TagName>{name}</TagName>
                        {(description!==null)?(
                            <p dangerouslySetInnerHTML={{__html:description}}/>
                        ):null}
                    </TagPageHeading>

                    {numPages > 1?(
                        <Pagination
                            prevLink={PrevLink}
                            nextLink={NextLink}
                            currentPage={`${page+1}`}
                            totalPage={`${numPages}`}
                        />
                    ):null}


                    <PostRow>
                        {edges.map(({ node }: any) => (

                            <PostGrid key={'pg'+node.slug}>
                                <PostCardModern
                                    key={node.slug}
                                    title={node.title}
                                    image={
                                        (null == node.featured_media || null === node?.featured_media?.localFile?.childImageSharp?.fluid)
                                            ? null
                                            : node.featured_media.localFile.childImageSharp.fluid
                                    }
                                    url={node.slug}
                                    description={node.excerpt}
                                    tags={node.tags}
                                    placeholderBG={getRandomColor(colors)}
                                />
                            </PostGrid>



                        ))}

                    </PostRow>



                </BlogDetailsContent>
                <Sidebar/>
            </BlogPostDetailsWrapper>
        </Layout>
    )
}
export default Tag;

export const pageQuery = graphql`
query($slug: String,$page: Int,$perPage: Int){

    wordpressTag(slug: {eq: $slug}) {
    description
    slug
  }

  allWordpressPost(limit: $perPage,skip: $page, sort: {fields: date, order: DESC}, filter: {tags: {elemMatch: {slug: {eq: $slug}}}}) {
  totalCount
    edges {
      node {
        tags{
          slug
          name
        }
        slug
        date
        title
        excerpt
        featured_media {
          localFile {
            relativePath
            childImageSharp {
              fluid(maxWidth: 370, maxHeight: 220, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
      }
      }
    }
  }
}
`;
